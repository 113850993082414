import React from "react"
import styled from "styled-components"
import { graphql  } from "gatsby"

import Page from "../components/layout/Page"
import SEO from "../components/seo"
import GoogleImage from "../components/GoogleImage"
import PageHeader  from "../components/layout/PageHeader"

import { devices, storage } from "../settings"

export default function ProductOptions({ data }) {
    const product = data.products.edges[0].product;
    const content = data.content.edges[0].content;
    
    return (
        <>
            <SEO page="productoption" title={content.title} />

            <Page 
                transparent
                padding={0}
                breadcrumb={[
                    {title: "Home", url:"/"},
                    {title: "Products", url:"/products/"},                    
                    {title: product.category, url: "/products/" + product.category_name + "/"},
                    {title: product.title, url: "/products/" + product.url + "/"},
                    {title: content.title, url: null}
                ]}
                >
                    <PageHeader 
                        title={content.title}
                    />
                    <div className="content" dangerouslySetInnerHTML={{__html: content.description}} />
                    <div>
                        <GoogleImage root src={content.image_url} required lazy alt={content.title} />
                    </div>

            </Page>
        </>
    )
}

export const query = graphql`
    query($productName: String!, $name: String!) {
        products: allMysqlProducts(filter: {name: {eq: $productName}}) {
            edges {
                product: node {
                    id
                    name
                    url
                    title
                    category
                    category_name
                }
            }
        }
        content: allMysqlProductContent(filter: {product_name: {eq: $productName}, name: {eq: $name}}) {
            edges {
                content: node {
                    id
                    type
                    name
                    title
                    description
                    optional_ind
                    image_url
                }
            }
        }
    }`